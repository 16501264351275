<template>
  <div class="c-footer gray-p--bg">
    <div class="c-footer__main container d-flex justify-between">
      <a
        :href="`/`"
        class="logo c-footer__logo active"
        @click.prevent="$router.push({ path: `/` })"
      >
        <img
          loading="lazy"
          src="~/assets/images/logo.svg"
          alt="logo"
        >
        Домашний интернет {{ getAddressText.cityOnlyLoc }}
      </a>
      <nav class="c-footer__nav title-5 d-flex align-center">
        <a
          v-for="link in links"
          :href="link.url"
          :class="{ active: link.url === $route.path }"
          class="c-footer__link black--text"
          @click.prevent="$router.push({ path: link.url })"
        >{{ link.name }}</a>
      </nav>
      <div class="c-footer__socials d-flex">
        <button
          v-if="socials.vk"
          @click="goAway(`/away/?to=${socials.vk}`)"
        >
          <img
            loading="lazy"
            src="@/assets/images/useful/vk.svg"
            alt="VK"
          >
        </button>
        <button
          v-if="socials.yt"
          @click="goAway(`/away/?to=${socials.yt}`)"
        >
          <img
            loading="lazy"
            src="@/assets/images/useful/yt.svg"
            alt="YouTube"
          >
        </button>
      </div>
    </div>
    <div class="c-footer__bottom black--bg white--text">
      <div class="container d-flex justify-center">
        <p class="white--text">
          © {{ year }} ООО «ДомИнтернет»
        </p>
        <p class="gray-inactive--text">
          Продолжая использовать наш сайт, вы даете согласие на обработку файлов
          Cookies и других пользовательских данных, в соответствии с
          <button
            class="white--text"
            @click="goAway('/legal/privacy/')"
          >
            Политикой обработки персональных данных.
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { goAway } from '~/helpers'
import { useCities } from '~/store/cites/cities.store'

defineProps({
  socials: {},
})

const cityStore = useCities()
const getAddressText = computed(() => cityStore.getAddressText)

const links = ref([
  {
    name: 'О проекте',
    url: '/about/',
  },
  {
    name: 'История',
    url: '/about/history/',
  },
  {
    name: 'Контакты',
    url: '/contacts/',
  },
])

const year = new Date().getFullYear()
</script>

<style scoped lang="scss">
.c-footer {
  &__main {
    padding-top: 32px;
    padding-bottom: 32px;
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  &__logo {
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 32px;
    }
  }

  &__nav {
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 40px;
    }
  }
  &__link {
    &:not(:last-child) {
      margin-right: 32px;
    }
  }

  &__socials {
    a,
    button {
      display: flex;
      &:last-child {
        margin-left: 16px;
      }
    }
  }

  &__bottom {
    font-size: fontSize(small);
    padding-top: 8px;
    padding-bottom: 8px;
    .white--text {
      margin-right: 4px;

      @media (max-width: getBreakpoint(tablet)) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
    & > .d-flex {
      @media (max-width: getBreakpoint(tablet)) {
        flex-direction: column;
      }
    }
  }
}
</style>
