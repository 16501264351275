<template>
  <section class="c-reviews container">
    <h2 class="c-reviews__title">
      Отзывы сотрудников
    </h2>

    <VLazy
      :min-height="100"
    >
      <LazyVSwiper
        id="c-reviews"
        slides-per-view="auto"
        :space-between="12"
        :threshold="3"
        :css-mode="!disabledPortal"
        :centered-slides="!disabledPortal"
        :centered-slides-bounds="!disabledPortal"
        navigation
        :pagination="{ clickable: true }"
        class="relative"
        @swiper="onSwiper"
      >
        <swiper-slide
          v-for="(photo, index) in reviews"
          :key="'photo' + index"
        >
          <a
            class="c-reviews__img"
            :data-pswp-width="photo.width"
            :data-pswp-height="photo.height"
            :href="imgUrl(photo.url, 700, 0)"
            target="_blank"
            rel="noreferrer"
          >
            <img
              loading="lazy"
              :src="imgUrl(photo.url, 300, 0)"
              :alt="'photo' + index"
            >
          </a>
        </swiper-slide>

        <div class="swiper-btn-wrap swiper-btn-wrap--prev" />
        <div class="swiper-btn-wrap" />
      </LazyVSwiper>
    </VLazy>
  </section>
</template>

<script setup lang="ts">
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import { useMainStore } from '~/store/main/main.store'
import { imgUrl } from '~/helpers'

defineProps({
  reviews: {
    type: Array,
  },
})

const ctx = useNuxtApp()
const lightbox = ref()
const mainStore = useMainStore()
const disabledPortal = computed(() => mainStore.disabledPortal)

const onSwiper = () => {
  if (!lightbox.value) {
    lightbox.value = new PhotoSwipeLightbox({
      gallery: '#c-reviews',
      children: 'a',
      tapAction: 'close',
      pswpModule: () => import('photoswipe'),
    })
    lightbox.value.init()
  }
}
onBeforeUnmount(() => {
  if (lightbox.value) {
    lightbox.value.destroy()
    lightbox.value = null
  }
})
</script>

<style scoped lang="scss">
.c-reviews {
  margin-bottom: 64px;

  @media (max-width: getBreakpoint(tablet)) {
    margin-bottom: 40px;
  }

  &__img {
    height: 330px;
    width: 240px;
    border-radius: 16px;
    background: color(white);
    padding: 8px;
    img {
      max-height: 100%;
      object-position: center;
    }
  }
  &:deep(.swiper-wrapper) {
    @media (max-width: getBreakpoint(desktop)) {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding-top: 12px;
    }
  }
  &:deep(.swiper-btn-wrap) {
    background: linear-gradient(
      90deg,
      rgba(color(gray-p), 0) 0%,
      color(gray-p) 100%
    );
  }
}
</style>
