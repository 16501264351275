<template>
  <div
    ref="parent"
    class="city-modal white--bg"
    data-test="city-change-modal"
  >
    <div
      class="city-modal__wrap d-flex flex-column flex-grow"
      :class="{ 'p-color': provider }"
      :style="{
        '--mainColor': provider && color.main,
        '--font': provider && color.font,
      }"
    >
      <div class="city-modal__header gray-p--bg relative">
        <d-btn
          data-test="back-button"
          icon
          color="custom"
          class="modal__back"
          @click="$emit('close')"
        >
          <img
            loading="lazy"
            src="@/assets/images/svg/arrow-right.svg"
            alt="arrow"
            style="transform: rotate(180deg)"
          >
        </d-btn>
        <p class="city-modal__title headline-6 text-center">
          Введите населенный пункт
        </p>
        <form
          autocomplete="off"
          class="city-modal__form d-flex relative"
          name="form"
        >
          <DTextAreaPlaceholder
            ref="cityRef"
            :city="city"
            @input="input"
            @keydown="keyDown"
          />
          <div class="city-modal__actions d-flex align-center">
            <img
              v-if="city"
              loading="lazy"
              src="@/assets/images/svg/chips-close_gray.svg"
              class="city-modal__clear d-flex"
              alt="close"
              style="margin-right: 8px"
              @click="clearCity"
            >
            <div
              v-show="showSelectCity.some((el) => !!el)"
              style="margin-left: 4px"
              class="d-flex city-modal__confirm primary--bg"
              @click="selectCity"
            >
              <img
                loading="lazy"
                src="@/assets/images/svg/arrow-right_white.svg"
                alt="arrow"
              >
            </div>
          </div>
        </form>
      </div>
      <div
        class="city-modal__results d-flex"
        :class="{ loading: loading }"
      >
        <div
          v-if="popperShow"
          :class="{ 'city-modal__popper-active': popperShow }"
          class="city-modal__popper d-flex flex-column relative"
        >
          <button
            v-for="(item, index) in responseHouses"
            :key="item.autogenerated_url + index"
            class="city-modal__btn justify-center d-flex flex-column"
            @click.prevent="chooseHouse(item)"
          >
            <span class="relative d-flex align-center">
              <img
                loading="lazy"
                src="@/assets/images/svg/pin-thin.svg"
                class="city-modal__btn-icon"
                alt="pin"
                style="min-width: 20px; width: 20px; height: 20px; opacity: .5;"
              >
              <span
                data-test="hint-city-change-modal"
                class="city-modal__btn-title"
                v-html="addressWithStrong(item.number)"
              />
            </span>
            <small
              v-if="item.parents_tooltip"
              class="city-modal__btn-subtitle gray-dark--text subhead-6"
            >{{ item.parents_tooltip }}</small>
          </button>
          <button
            v-for="(item, index) in responseCities"
            :key="item.fias_id + index"
            class="city-modal__btn justify-center d-flex flex-column"
            @click="chooseAddress(item)"
          >
            <span class="relative d-flex align-center">
              <img
                loading="lazy"
                src="@/assets/images/svg/pin-thin.svg"
                class="city-modal__btn-icon"
                alt="pin"
                style="width: 20px; height: 20px; opacity: .5;"
              >
              <span
                data-test="hint-city-change-modal"
                class="city-modal__btn-title"
                v-html="addressWithStrong(itemTitle(item))"
              />
            </span>
            <small
              v-if="item.parents_tooltip"
              class="city-modal__btn-subtitle gray-dark--text subhead-6"
            >{{ item.parents_tooltip }}</small>
          </button>
          <div
            v-show="isError"
            class="city-modal__error d-flex flex-column justify-center align-center flex-grow"
          >
            <p>К сожалению, мы ничего не нашли.</p>
            <span>Попробуйте изменить условия поиска</span>
          </div>
        </div>
        <template v-else-if="disabledMobile">
          <div
            ref="refRegions"
            class="city-modal__regions"
          >
            <div
              v-for="(letter, index) in filteredRegions"
              v-if="filteredRegions"
              :key="letter"
              class="city-modal__letter d-flex flex-column"
            >
              <p class="title-5">
                {{ letter[0].toUpperCase() }}
              </p>
              <button
                v-for="region in letter[1]"
                :id="region.fias_id"
                :key="region.fias_id"
                :class="{ active: selectedRegion === region.fias_id }"
                @click="selectRegion(region.fias_id)"
              >
                {{ region.full_name ? region.full_name : itemTitle(region) }}
              </button>
            </div>
          </div>
          <div
            ref="refCities"
            class="city-modal__cities d-flex flex-column"
          >
            <template
              v-if="selectedRegion"
            >
              <button
                v-for="rCity in regionsCities"
                :key="'rg' + rCity.fias_id"
                @click="chooseAddress(rCity)"
              >
                {{
                  (rCity.short_name ? rCity.short_name + "\u00A0" : "")
                    + rCity.name
                }}
              </button>
            </template>
          </div>
        </template>
        <div
          v-else
          ref="refModalRwc"
          class="city-modal__rwc"
        >
          <ul>
            <li
              v-for="region in filteredRegions.map((el) => el[1]).flat()"
              v-if="filteredRegions"
              :id="region.fias_id"
              :key="region.fias_id"
              class="city-modal__rwc-region"
            >
              <button
                class="title-5 w-100 d-flex"
                @click="changeSelectedRegion(region.fias_id)"
              >
                {{ region.full_name ? region.full_name : itemTitle(region) }}
                <img
                  :class="{ rotate: selectedRegion === region.fias_id }"
                  style="transition: transform 0.24s; margin-left: auto; width: 24px; height: 24px;"
                  loading="lazy"
                  src="@/assets/images/svg/arrow-expand.svg"
                  alt="arrow"
                >
              </button>
              <LazyVCollapse
                :show-more="
                  selectedRegion === region.fias_id
                "
              >
                <div class="d-flex flex-column">
                  <button
                    v-for="city in regionsCities?.filter(
                      (el) => el.region_fias_id === region.fias_id,
                    )"
                    :key="city.fias_id"
                    class="title-5 gray-dark--text"
                    style="margin-top: 8px"
                    @click="chooseAddress(city)"
                  >
                    {{ itemTitle(city) }}
                  </button>
                </div>
              </LazyVCollapse>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { BaseAddressObject } from '~/api'
import { useCities } from '~/store/cites/cities.store'
import { useTariffs } from '~/store/tariffs/tariffs.store'
import { debounce } from '~/helpers'
import { useMainStore } from '~/store/main/main.store'
import { getAddresLocation } from '~/composible/getAddresLoc'
import { scrollToBlock } from '~/composible/scrollToBlock'
import { recentSearchFn } from '~/composible/recentSearch'

const emit = defineEmits(['changedCity', 'close'])

const ctx = useNuxtApp()
const $route = useRoute()
const $router = useRouter()
const cityStore = useCities()
const tariffsStore = useTariffs()
const mainStore = useMainStore()
const parent = ref()
const disabledMobile = computed(() => mainStore.disabledMobile)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const oldClientCookie = useCookie('oldClient', { maxAge: 60 * 60 * 24 })
const providerClientCookie = useCookie('providerClient', { maxAge: 60 * 60 * 24 })
const city = ref('')
const cityRef = ref()
const allCities = ref()
const refCities = ref()
const refRegions = ref()
const refModalRwc = ref()
const allRegions = computed(() => cityStore.getAllRegions)
const selectedRegion = ref('')
const popperShow = ref(false)
const loading = ref(false)
const isError = ref(false)
const eventInput = ref(true)
const log = ref([])
const provider = computed(() => mainStore.getDomainConfig)
const addressAncestors = computed(() => cityStore.getAddressAncestors)
const color = computed(() => {
  if (provider.value?.provider.config?.color)
    return provider.value?.provider.config?.color
})
const currentCity = computed(() => cityStore.getCity)
const streetPage = computed(() => cityStore.getDataStreet)
const isSearchAddressPage = computed(
  () =>
    $route.name === 'personal-address-slug'
    || $route.name === 'city-streets-street'
    || $route.name === 'city-address-slug',
)

const regionsCities = computed(() => {
  return allCities.value
})

const changeSelectedRegion = (value: string) => {
  const needFetch = selectedRegion.value !== value
  selectedRegion.value = selectedRegion.value === value ? '' : value
  if (needFetch) fetchCities()
}

const selectRegion = (fias_id) => {
  selectedRegion.value = fias_id
  fetchCities()
  if (refCities.value) {
    refCities.value.scrollTo({ top: 0, behavior: 'smooth' })
  }
}

function jaccardSimilarity(s1, s2) {
  const set1 = new Set(s1)
  const set2 = new Set(s2)
  const intersection = new Set([...set1].filter(item => set2.has(item)))
  const union = new Set([...set1, ...set2])
  const similarity = intersection.size / union.size
  return similarity
}

const showSelectCity = computed(() =>
  responseCities.value?.map(
    el =>
      jaccardSimilarity(el.name.toLowerCase(), city.value.toLowerCase()) >= 1,
  ) || [],
)

const responseList = ref()
const responseCities = computed(() => responseList.value?.address_objects)
const responseHouses = computed(() => responseList.value?.houses)

const getSuggestions = async (queryString: string, cb: (arg) => void) => {
  if (city.value) {
    loading.value = true
    try {
      const response = await $fetch(`https://${mainStore.domain}/api/suggestions/multi/`, {
        method: 'GET',
        headers: {
          ...getCommonHeaders.value,
        },
        params: {
          query: queryString,
          limit: queryString.length ? undefined : 10,
          site_id: import.meta.env.VITE_SITE_ID,
        },
      })
      cb(response)
      popperShow.value = true
      loading.value = false
      if (response.address_objects.length || response.houses.length) isError.value = false
      else isError.value = true
    }
    catch (e) {
      isError.value = true
      cb([])
    }
  }
  else {
    popperShow.value = false
  }
}

const debounceSearch = debounce(() => {
  getSuggestions(city.value.trim(), (cb) => {
    responseList.value = cb
  })
}, 300)

const textareaHeight = () => {
  cityRef.value.cityRef.style.height = 'auto'
  cityRef.value.cityRef.style.height
    = (+cityRef.value.cityRef.scrollHeight > 72
      ? 72
      : +cityRef.value.cityRef.scrollHeight) + 'px'
  cityRef.value.cityRef.style.overflowY
    = +cityRef.value.cityRef.scrollHeight > 72 ? 'scroll' : 'hidden'
}

const input = (event) => {
  if (eventInput.value) {
    eventInput.value = false
    ctx.$event('gtmSendEvent', {
      event: 'trackEvent',
      category: 'address',
      action: 'city-typing',
      label: '',
    })
  }
  event.target.composing = false
  city.value = event.target.value
  debounceSearch()
  textareaHeight()
}

const keyDown = (e) => {
  if (e.keyCode === 13) e.preventDefault()
}

const selectCity = () => {
  const index = showSelectCity.value.indexOf(true)
  chooseAddress(responseCities.value?.[index])
}

const clearCity = () => {
  city.value = ''
  nextTick(() => {
    cityRef.value?.cityRef.focus()
    textareaHeight()
    popperShow.value = false
    responseList.value = undefined
  })
}

const chooseAddress = async (cityResponse: BaseAddressObject) => {
  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'address',
    action: 'city-confirmed',
    label: '',
  })
  const redirect = isSearchAddressPage.value
  cityRef.value?.cityRef.focus()
  const oldCitySlug = currentCity.value?.url
  let res

  try {
    res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${cityResponse.fias_id}/ancestors/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        include_self: true,
        morph: true,
      },
    })
  }
  catch (e) {
  }
  finally {
  }
  if (cityResponse.level === 'city') {
    const cityObj = res?.address_objects.find(address => address.level === 'city')
    cityStore.$patch({
      addressAncestors: res,
    })
    cityStore.house = {}
    tariffsStore.passThroughProviderId = null

    const cookieCity = useCookie('currentCity')
    const cookieAddress = useCookie('currentAddress')

    cookieCity.value = cityObj.fias_id
    cookieAddress.value = ''

    if (redirect) {
      $router.replace(`/${cityObj.url}/`)
    }
    else if (oldCitySlug !== cityObj.url) {
      $router.replace($route.path.replace(oldCitySlug!, cityObj.url!))
      cityStore.house = {}
      tariffsStore.passThroughProviderId = null
    }
    else {
      cityStore.house = {}
      tariffsStore.passThroughProviderId = null
    }
    nextTick(() => {
      emit('close')
    })
  }
  else {
    city.value = cityResponse.parents_tooltip
      ? cityResponse.parents_tooltip + ' ' + itemTitle(cityResponse) + ' '
      : itemTitle(cityResponse) + ' '
    debounceSearch()
  }
}
const chooseHouse = (st) => {
  nextTick(() => {
    city.value = st.parents_tooltip
      ? st.parents_tooltip + ' ' + st.number
      : (currentCity.value ? itemTitle(currentCity.value) + ' ' : '')
        + ' '
        + st.number
  })
  changeAddress(st)
  // emit('close')
}
const { addRecentSearch } = recentSearchFn()
const changeAddress = (house) => {
  const oldCitySlug = currentCity.value?.url
  ctx.$event('gtmSendEvent', {
    event: 'trackEvent',
    category: 'address',
    action: 'confirmed',
    label: '',
  })
  nextTick(async () => {
    log.value = []
    let res
    let loc
    try {
      const { getAddresLoc } = getAddresLocation()

      const promiseLoc = async () => {
        loc = await getAddresLoc({
          url: house!.autogenerated_url,
          houseId: house!.id,
          houseFiasId: house!.dadata?.fias_id,
          streetFiasId:
            house!.dadata?.house
            && house!.dadata?.parent_fias_id
              ? house!.dadata?.parent_fias_id
              : undefined,
          houseNumber:
            house!.dadata?.house
            && house!.dadata?.parent_fias_id
              ? house!.dadata?.house
              + (house!.dadata?.block_type
                ? ' ' + house!.dadata?.block_type
                : '')
              + (house!.dadata?.block
                ? ' ' + house!.dadata?.block
                : '')
              : undefined,
        })
      }
      const promiseRes = async () => {
        if (house!.dadata?.parent_fias_id) {
          res = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/${house!.dadata?.parent_fias_id}/ancestors/`, {
            method: 'GET',
            headers: {
              ...getCommonHeaders.value,
            },
            params: {
              include_self: true,
              morph: true,
            },
          })
        }
      }

      if (!provider.value) {
        mainStore.$patch({
          showLoader: true,
        })
      }
      await promiseLoc()
      await promiseRes()
    }
    catch (e) {
      ctx.$sentryCaptureMessage(e)
      // result = []
      // showLoader: false
    }
    finally {
      let newStreet
      let newCity = res?.address_objects?.find(
        el => el.level === 'city',
      )
      if (!newCity)
        newCity = res?.address_objects?.find(
          el => el.level === 'street',
        )
      else
        newStreet = res?.address_objects?.find(
          el => el.level === 'street',
        )
      cityStore.$patch({
        addressAncestors: res,
      })
      mainStore.$patch({
        wrongAddress: false,
      })

      addRecentSearch({
        name: city.value,
        houseUrl: loc.autoGeneratedUrl,
        parents_tooltip:
          (newCity?.short_name
            ? newCity.short_name + ' '
            : '') + newCity?.name,
        selectedHouse: house,
      })

      const coockieAddress = useCookie('currentAddress')

      coockieAddress.value = house!.autogenerated_url
      mainStore.$patch({
        oldClient: false,
        providerClient: undefined,
      })
      oldClientCookie.value = null
      providerClientCookie.value = null

      if (newCity) {
        const coockieCity = useCookie('currentCity')
        coockieCity.value = newCity.fias_id
      }

      if (
        (!provider.value)
      ) {
        $router.push({
          path: `/personal/address/${loc.autoGeneratedUrl}/`,
        })
      }
      else {
        if (newCity)
          $router.replace({
            path: $route.fullPath.replace(oldCitySlug, currentCity.value?.url),
          })
        if (provider.value.provider.slug !== 'mts') {
          const element = document.getElementsByClassName('layout')[0]
          scrollToBlock(element, 500)
        }
      }
      emit('close')
    }
  })
}

const itemTitle = (city: BaseAddressObject | undefined) => {
  return city
    ? `${city.short_name ? city.short_name + ' ' : ''}${city.name}`
    : ''
}

const addressWithStrong = (title: string) => {
  if (!city.value) return title
  const words = city.value.trim().split(/[^0-9а-яА-ЯёЁ]+/gmi).filter(Boolean).sort((a, b) => b.length - a.length)
  let newTitle = title
  const regexList = words.map(word => new RegExp(`(?!<strong[^>]*?>)(${word})(?![^<]*?</strong>)`, 'giu'))

  regexList.forEach((regex) => {
    newTitle = newTitle.replace(regex, match => `<strong class='cl-pr--t'>${match}</strong>`)
  })
  return newTitle
}

const fetchRegions = async () => {
  let regions
  try {
    regions = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        city_type: 'all',
        min_providers: 1,
        providers: provider.value ? [provider.value.provider.id] : undefined,
        sort: 'NAME',
        level: 'region',
        limit: 5000,
        optimized: true,
      },
    })
  }
  catch {}
  cityStore.$patch({
    allRegions: regions,
  })
  openCurrentRegion()
}
const fetchCities = async () => {
  loading.value = true
  let cities
  try {
    cities = await $fetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        city_type: 'all',
        sort: 'POPULATION',
        fias_id: selectedRegion.value,
        providers: provider.value ? [provider.value.provider.id] : undefined,
        level: 'city',
        limit: 15,
        with_region_fias_ids: true,
        optimized: true,
      },
    })
  }
  catch {}
  loading.value = false
  allCities.value = cities
  if (!disabledMobile.value) {
    setTimeout(() => {
      const distance
        = (document.getElementById(selectedRegion.value)?.offsetTop || 0)
        - (refModalRwc.value?.offsetTop || 0)
      if (refModalRwc.value) {
        refModalRwc.value.scrollTo({
          top: distance,
          behavior: 'smooth',
        })
      }
    }, 300)
  }
}

const openCurrentRegion = async () => {
  const currentRegion
    = addressAncestors.value?.address_objects[
      addressAncestors.value?.address_objects?.length - 1
    ]
  const regions = allRegions.value?.map(el => el.fias_id)
  if (regions?.includes(currentRegion.fias_id)) {
    if (disabledMobile.value) {
      changeSelectedRegion(currentRegion.fias_id)
      selectedRegion.value = currentRegion.fias_id
      await fetchCities()
      if (refCities.value) {
        refCities.value.scrollTo({ top: 0, behavior: 'smooth' })
      }
      const distance
        = (document.getElementById(selectedRegion.value)?.offsetTop || 0)
        - (refRegions.value?.offsetTop || 0)
      if (refRegions.value) {
        refRegions.value.scrollTo({
          top: distance - 40,
          behavior: 'smooth',
        })
      }
    }
    else changeSelectedRegion(currentRegion.fias_id)
  }
}

const sortedRegions = computed(() => {
  return allRegions.value
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .reduce((obj, item) => {
      obj[item.name[0].toLowerCase()] = obj[item.name[0].toLowerCase()] || []
      obj[item.name[0].toLowerCase()].push(item)
      return obj
    }, {})
})
const filteredRegions = computed(() => {
  if (!sortedRegions.value) return
  else return Object.entries(sortedRegions.value)
})

onBeforeMount(() => {
  if (!allRegions.value) fetchRegions()
  else openCurrentRegion()
})
const scrollTopFn = () => {
  parent.value.scrollTo(0, 0)
  window.removeEventListener('resize', scrollTopFn)
}
onMounted(() => {
  const inputAddress = document.getElementById('city-textarea')
  if (inputAddress) nextTick(() => inputAddress.focus())
  nextTick(() => parent.value.scrollTo(0, 0))
  if (ctx.$device.isAndroid) {
    window.addEventListener('resize', scrollTopFn)
  }
})

onBeforeUnmount(async () => {
  if (ctx.$device.isAndroid) {
    window.removeEventListener('resize', scrollTopFn)
  }
})
</script>

<style scoped lang="scss">
.city-modal {
  max-height: 100vh;
  height: 561px;
  width: 780px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 16px;
  @media (max-width: getBreakpoint(tablet)) {
    max-height: unset;
    height: 100%;
    border-radius: 0;
  }

  &__wrap {
    height: 100%;
    overflow: hidden;
    &.p-color {
      .city-modal__confirm {
        background: var(--mainColor);
        color: var(--font);
      }
    }
  }

  &__header {
    height: 200px;
    padding: 40px;
    border-radius: 16px 16px 0 0;
    @media (max-width: getBreakpoint(tablet)) {
      border-radius: 0;
      background: transparent;
      padding: 8px 0 16px 0;
      height: 130px;
    }
    .modal__back {
      position: absolute;
      left: 16px;
      top: 16px;
      background: color(white);
      @media (max-width: getBreakpoint(tablet)) {
        left: 0;
        top: 0;
      }
    }
  }
  &__title {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(mobile-md)) {
      padding-left: 40px;
    }
  }

  &__textarea {
    padding: 14px 48px 14px 28px;
    border-radius: 40px;
    resize: none;
    background: color(gray-light-3);
    border: 1px solid color(gray-2);
    transition: border-color 0.3s;
    line-height: 1.5;
    min-height: 48px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 9px 48px 9px 28px;
      min-height: 40px;
    }

    &:focus, &:hover {
      border-color: color(secondary-dark);
    }

    &::placeholder {
      color: color(gray-2);
    }
  }
  &__results {
    height: calc(100% - 200px);
    overflow: hidden;
    transition: opacity 0.24s;
    @media (max-width: getBreakpoint(tablet)) {
      height: unset;
    }

    &.loading {
      opacity: 0.2;
    }
  }

  &__regions,
  &__cities {
    flex: 0 0 50%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 40px;
    border-right: 1px solid color(gray-p);
  }
  &__rwc {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;

    &-region {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding-bottom: 12px;
    }
  }

  &__letter {
    &:not(:first-child) {
      margin-top: 24px;
    }
    button {
      margin-top: 12px;
    }
  }
  &__cities {
    button {
      margin-bottom: 12px;
    }
  }
  &__letter,
  &__cities {
    button {
      color: color(secondary-dark);
      transition: color 0.24s;
      &:hover,
      &.active {
        color: color(primary);
      }
    }
  }

  &__clear {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  &__actions {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
  }
  &__confirm {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: getBreakpoint(mobile-lg)) {
      width: 34px;
      height: 34px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      width: 32px;
      height: 32px;
    }
    img {
      width: 28px;
      height: 28px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 26px;
        height: 26px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__form {
    margin-bottom: 12px;
  }

  &__popper {
    padding: 0 40px 0 68px;
    flex: 1 1 auto;

    @media (max-width: getBreakpoint(tablet)) {
      padding: 0 0 0 24px;
    }

    &-active {
      overflow-y: auto;
    }
  }

  &__btn {
    border-bottom: 1px solid color(gray-light);
    height: 57px;
    min-height: 57px;

    &-title:deep(strong) {
      color: color(primary);
      font-weight: 400;
    }

    &-icon {
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__error {
    p {
      margin-bottom: 8px;
    }

    span {
      font-size: fontSize(paragraph);
    }
  }

  @media (max-width: getBreakpoint(tablet)) {
    padding: 12px 16px;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    &__textarea {
      padding: 8px 40px 8px 24px;
      border-radius: 20px;
      font-size: fontSize(paragraph);
      min-height: 40px;
    }
    &__btn {
      height: 53px;
      min-height: 53px;

      &-title {
        font-size: fontSize(paragraph);
      }

      &-subtitle {
        font-size: fontSize(small);
      }

      &-icon {
        left: -24px;
      }
    }
  }
}
</style>
