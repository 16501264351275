<template>
  <div
    id="c-form"
    class="c-form d-flex justify-center"
  >
    <form
      class="c-form__form blue--bg d-flex flex-column relative align-center"
      data-test="resume-form"
      action="#"
      @submit.prevent="submit"
    >
      <p class="c-form__title headline-2">
        Заполни анкету сейчас
      </p>
      <p class="c-form__subtitle gray-dark--text title-2">
        Мы свяжемся с тобой в ближайшее время
      </p>
      <div class="c-form__inputs d-flex flex-wrap">
        <div class="c-form__input">
          <p class="c-form__label subhead-3 gray-b--text">
            Имя<span class="primary--text">*</span>
          </p>
          <LazyVTextInput
            ref="dName"
            v-model:value="clientName"
            class="w-100 q-modal__section"
            :validation-length="2"
            enterkeyhint="send"
            tabindex="2"
            @validate="isValidName = $event"
          />
        </div>
        <div class="c-form__input">
          <p class="c-form__label subhead-3 gray-b--text">
            Фамилия<span class="primary--text">*</span>
          </p>
          <LazyVTextInput
            ref="dSName"
            v-model:value="clientSecondName"
            placeholder="Фамилия"
            :validation-length="2"
            class="sname"
            enterkeyhint="send"
            tabindex="2"
            @validate="isSecondValidName = $event"
          />
        </div>
        <div class="c-form__input">
          <p class="c-form__label subhead-3 gray-b--text">
            Телефон с WhatsApp<span class="primary--text">*</span>
          </p>
          <LazyVPhoneInput
            ref="dPhone"
            v-model:value="phoneNumber"
            @validate="isValidPhone = $event"
          />
        </div>
        <div class="c-form__input">
          <button
            type="button"
            class="c-form__file d-flex align-center justify-center"
            @click.prevent="addFile"
          >
            <span class="secondary-dark--text title-5 text-no-wrap">{{
              fileName || "Прикрепить резюме"
            }}</span>
          </button>
          <input
            ref="fileInput"
            class="c-form__file-input"
            type="file"
            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, text/rtf, text/html, application/pdf, image/*"
            @change="handleFileChange"
          >
        </div>
      </div>
      <p class="c-form__wo-file subhead-6 gray-dark--text">
        Желательно, но можно и без него
      </p>
      <div class="c-form__about w-100">
        <p class="c-form__label subhead-3 gray-b--text">
          Расскажи о себе
        </p>
        <textarea
          ref="textareaRef"
          rows="1"
          autocomplete="on"
          autocorrect="on"
          autocapitalize="on"
          :spellCheck="true"
          class="c-form__about-me subhead-3"
          placeholder="Здесь ты можешь написать о своём опыте работы и достижениях."
          :value="aboutMe"
          @input="input"
        />
      </div>

      <d-btn
        :loading="loading"
        class="c-form__submit title-4"
        color="primary"
        :disabled="!isValidPhone || !isSecondValidName || !isValidName"
        @click.prevent="submit"
      >
        Отправить анкету
      </d-btn>
      <p class="subhead-6">
        Отправляя заявку, вы соглашаетесь с
        <button
          class="secondary-dark--text text-underline"
          @click="goAway('/legal/privacy/')"
        >
          политикой обработки персональных
        </button>
        данных
      </p>

      <div
        v-if="submited"
        class="c-form__submited headline-2 d-flex justify-center align-center"
      >
        Анкета успешно отправлена
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios'
import { goAway } from '~/helpers'

const ctx = useNuxtApp()
const loading = ref(false)
const submited = ref(false)
const phoneNumber = ref('')
const clientName = ref('')
const clientSecondName = ref('')
const aboutMe = ref('')
const fileName = ref('')
const fileAdded = ref()
const fileInput = ref()
const dPhone = ref()
const dName = ref()
const dSName = ref()
const isValidPhone = ref(false)
const isValidName = ref(false)
const isSecondValidName = ref(false)

const input = (event) => {
  event.target.composing = false
  aboutMe.value = event.target.value
}

const addFile = () => {
  fileInput.value.click()
}

const handleFileChange = (e) => {
  if (e.target.files.length) {
    fileName.value = e.target.files[0].name
    fileAdded.value = e.target.files[0]
  }
  else {
    fileName.value = ''
    fileAdded.value = undefined
  }
}

const gacid = () => {
  let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)')
  const raw = match ? decodeURIComponent(match[1]) : null
  if (raw) {
    match = raw.match(/(\d+\.\d+)$/)
  }
  const gacid = match ? match[1] : null
  if (gacid) {
    return gacid
  }
}

const yaid = () => {
  const match = document.cookie.match('(?:^|;)\\s*_ym_uid=([^;]*)')
  return match ? decodeURIComponent(match[1]) : ''
}

const submit = async () => {
  if (!isValidPhone.value || !isValidName.value || !isSecondValidName.value) {
    dPhone.value.onBlur()
    dName.value.onBlur()
    dSName.value.onBlur()
    return
  }

  loading.value = true
  const formData = new FormData()
  formData.append('resume', fileAdded.value)

  const body = {
    first_name: clientName.value,
    last_name: clientSecondName.value,
    middle_name: '',
    phone: '+7' + phoneNumber.value,
    about: aboutMe.value,
    additional: {
      yandex_id: yaid() || '',
      google_id: gacid() || '',
    },
  }
  formData.append('body', JSON.stringify(body))

  axios
    .post('https://api.dominter.net/interactions/career_form/', formData)
    .then((response) => {
      console.log('Данные успешно отправлены', response)
      loading.value = false
      clientName.value = ''
      clientSecondName.value = ''
      phoneNumber.value = ''
      aboutMe.value = ''
      fileAdded.value = undefined
      submited.value = true
      isValidPhone.value = false
      isValidName.value = false
      isSecondValidName.value = false
      ctx.$event('gtmSendEvent', {
        event: 'trackEvent',
        category: 'form',
        action: 'success',
        label: 'resume',
      })
    })
    .catch((error) => {
      console.error('Ошибка отправки данных', error)
      loading.value = false
    })
}
</script>

<style scoped lang="scss">
.c-form {
  padding-top: 64px;
  position: relative;
  margin-bottom: 64px;
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 40px;
    margin-bottom: 32px;
  }
  &:before {
    content: "";
    background: color(gradient);
    position: absolute;
    height: 300px;
    left: 0;
    top: 0;
    right: 0;
  }

  &__submited {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 40px 32px;
    border-radius: 16px;
    background: rgba(color(white), 0.8);
    z-index: 1;
  }

  &__form {
    width: 580px;
    max-width: calc(100% - 32px);
    padding: 40px 40px 12px;
    border-radius: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      padding: 24px 24px 12px;
    }
  }
  &__title {
    margin-bottom: 12px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 4px;
    }
  }
  &__subtitle {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      text-align: center;
      margin-bottom: 8px;
    }
  }
  &__inputs {
    width: 100%;
    justify-content: space-between;
  }
  &__input {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: calc(50% - 10px);
    @media (max-width: getBreakpoint(tablet)) {
      width: 100%;
    }
    .sname {
      &.err {
        &:after {
          content: 'Слимком короткая фамилия';
        }
      }
    }
  }
  &__file {
    border: 1px dashed color(secondary-dark);
    margin-top: auto;
    width: 100%;
    height: 56px;
    border-radius: 28px;
    @media (max-width: getBreakpoint(tablet)) {
      height: 44px;
    }
    span {
      max-width: calc(100% - 64px);
      direction: rtl;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-input {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
    }
  }
  &__label {
    margin-bottom: 4px;
  }
  &__wo-file {
    margin-top: 8px;
    margin-left: auto;
    width: calc(50% - 10px);
    text-align: center;
    @media (max-width: getBreakpoint(tablet)) {
      width: 100%;
    }
  }

  &__about {
    margin-top: 16px;
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 12px;
    }

    &-me {
      width: 100%;
      height: 120px;
      background: color(white);
      border: 1px solid color(gray-darker);
      border-radius: 8px;
      resize: none;
      padding: 16px;

      &::placeholder {
        color: color(gray-inactive);
      }
    }
  }

  &__submit {
    padding: 16px 32px;
    margin-bottom: 40px;
    @media (max-width: getBreakpoint(tablet)) {
      height: 44px;
      width: 100%;
      max-width: 400px;
      margin-bottom: 24px;
    }
  }
}
</style>
